<script setup lang="ts">
const svg = `<svg class=" h-4 md:h-[21px] w-auto" xmlns="http://www.w3.org/2000/svg" width="30" height="22" viewBox="0 0 30 22" fill="none">
  <path d="M14.73 0.00124125C14.7059 0.0252902 14.6879 0.0914247 14.6879 0.139523C14.6879 0.193633 14.5917 0.434122 14.4775 0.674611C13.8762 1.93718 13.1728 2.60454 10.3591 4.60661C9.5835 5.15974 8.6516 5.84513 8.29087 6.13372C5.74168 8.17788 4.61138 9.98155 4.40697 12.3383C4.35887 12.9636 4.40095 14.0398 4.49715 14.3344C4.5212 14.4066 4.41899 14.4787 4.07629 14.629C3.00612 15.098 1.81569 16.096 1.16036 17.07C0.721468 17.7193 0.342697 18.549 0.16233 19.2464C0.0360734 19.7214 0.00601223 19.9919 0 20.6472C0 21.0922 0.0180367 21.5791 0.0420856 21.7294L0.0961957 22L0.324661 21.3146C0.733492 20.0881 1.21447 19.2825 2.03815 18.4227C2.63937 17.8035 3.22857 17.3766 4.00415 17.0098C4.74966 16.6551 5.27874 16.5229 6.43309 16.3906C6.96818 16.3305 7.42511 16.2703 7.43713 16.2523C7.45517 16.2343 7.36498 15.8435 7.23873 15.3805C6.84192 13.9376 6.79382 13.144 7.02229 12.0377C7.17861 11.2561 7.52731 10.5527 8.17664 9.67492C8.42314 9.33223 9.21675 8.49653 10.305 7.41432C12.295 5.4363 12.8602 4.83508 13.4313 4.07753C13.9724 3.36809 14.3392 2.71276 14.5737 2.05742L14.766 1.52835L14.9103 1.96123C14.9885 2.20172 15.1809 2.6346 15.3372 2.9292C15.8603 3.92723 16.4435 4.61864 18.4756 6.65077C20.5919 8.76106 21.0067 9.21198 21.5118 9.91541C22.2633 10.9676 22.5519 11.7792 22.606 13.0177C22.6421 13.8715 22.594 14.2262 22.2873 15.3745C22.1671 15.8014 22.0889 16.1862 22.101 16.2222C22.119 16.2703 22.4376 16.3245 22.9968 16.3726C23.9768 16.4567 24.6622 16.601 25.3175 16.8656C27.2234 17.6231 28.7445 19.3727 29.3637 21.519C29.496 21.9579 29.508 21.976 29.5561 21.8136C29.6343 21.5491 29.6283 20.0641 29.5441 19.6372C29.1233 17.4427 27.4278 15.3745 25.3836 14.5689L25.0169 14.4246L25.053 14.1841C25.3416 12.2662 24.9207 10.3603 23.8746 8.85125C22.9246 7.48046 21.8244 6.48844 19.0347 4.5044C16.2451 2.52037 15.3973 1.67865 14.9344 0.410073C14.8442 0.163572 14.754 -0.0167954 14.73 0.00124125Z" fill="currentColor"/>
</svg>`;
const scroll = useNuxtApp().$utilities.isScroll;
const { width } = useWindowSize();
</script>

<template>
  <NuxtLink
    to="/"
    class="w-fit uppercase font-medium font-Amethysta flex items-center text-xl md:text-2xl group"
  >
    <span
      :class="
        scroll && width > 1024
          ? 'text-primary-300 dark:text-primary-400'
          : 'text-primary'
      "
      v-html="svg"
    />
    <span
      :class="scroll && width > 1024 ? '' : 'text-black dark:text-white'"
      class="group-hover:text-primary-900 dark:group-hover:text-primary-100"
    >
      bazari
    </span>
  </NuxtLink>
</template>

<style scoped></style>
